.blue {
  color: rgb(64, 169, 255);
}

.download-table {
  width: 125px;
  height: 32px;
  color: #fff;
  background-color: #1890ff;
  border-color: #1890ff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
}

.ant-modal-body {
  /* padding: 0 !important; */
}

.ant-menu-item a {
  color: #ffffff !important;
}

.main_title {
  color: #696a6b;
  text-align: center;
  padding: 5px 0;
  margin-bottom: 0;
  margin-top: 0;
  font-weight: bold;
}

.ncl_b {
  cursor: pointer;
  color: #29df01;
  border-bottom: 1px solid #000000;
  padding-bottom: 2px;
  display: inline-block;
  width: 35px;
  text-align: center;
}

.ncl_b_bottom {
  cursor: pointer;
  color: #ff750c;
  text-align: center;
  display: inline-block;
  width: 35px;
  text-align: center;
}

.select_before {
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  font-size: 14px;
  box-sizing: border-box;
  height: 32px;
  line-height: 30px;
  text-indent: 10px;
  border-right: none;
  display: inline-block;
  width: 152px;
}

.select_before_small {
  background-color: #f4f4f4;
  /* border: 1px solid #d9d9d9; */
  border-radius: 2px;
  font-size: 14px;
  box-sizing: border-box;
  height: 32px;
  line-height: 30px;
  text-indent: 10px;
  display: inline-block;
  width: 110px;
  margin-right: 5px;
}

.test {
  color: #353535;
}

.location_map_box_bg {
  /* background-color: #fefefe; */
  /* background-color: #ffffff; */
  margin: 3px;
  display: inline-block;
  width: 70px;
  height: 70px;
  box-sizing: border-box;
  border: 1px solid #8a8a8a;
  font-size: 14px;
  overflow: hidden;
  box-shadow: 1px 2px 3px 2px rgb(190, 190, 190);
}

.location_map_box_bg .each_div {
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fix_right_div {
  width: 10px;
  height: 14px;
  background-color: #29df01;
  border: 2px solid #ffffff;
  border-right: none;
}

.test {
  border: 1px solid #005cb3;
  color: #aaaaaa;
  color: #dbdbdb;
  color: #00cf9b;
  color: #e100c7;
  color: #ff2e2e;
}

.left_box {
  background-color: #f5f5f5;
  box-shadow: 1px 2px 3px 2px rgb(190, 190, 190);
}


.point_status {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background-color: #ffffff;
  border: 2px solid #1890ff;
  margin-right: 5px;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  text-align: center;
  line-height: 20px;
  font-weight: bold;
}

.occupation_point {
  background-color: #f50;
  border: 2px solid #f50;
}

.blank_point {}

.fixed_right_time_plan {
  position: fixed;
  left: 5px;
  bottom: 20px;
  background-color: rgba(251, 251, 251, 0.55);
  font-size: 18px;
  font-weight: bold;
  box-shadow: 1px 2px 3px 2px rgb(190, 190, 190);
  padding: 3px 7px;
  line-height: 22px;
  /* display: none; */
}

.normal_box {
  background-color: #e8e8e8;
  border: 2px solid #ffffff;
}

.normal_box_focus {
  background-color: #ffc8fd;
  border: 2px solid #f600ee;
}


.focus_box {
  background-color: #e10000;
  border: 2px solid #e10000;
  color: #ffffff;
  opacity: 0.7;
  font-weight: bold;
  font-size: 13px;
}

.has_occupy_box_1 {
  background-color: #fff3d4;
  border: 2px solid #fff3d4;
  color: #484848;
}

.has_occupy_box_2 {
  background-color: #f0d99c;
  border: 2px solid #f0d99c;
  color: #484848;
}

.has_occupy_box_3 {
  background-color: #d88273;
  border: 2px solid #d88273;
  color: #ffffff;
}

.has_occupy_box_4 {
  background-color: #bf444c;
  border: 2px solid #bf444c;
  color: #ffffff;
}